<template>
    <div>
        <img class="deconnexion" src="../assets/se-deconnecter.png" v-on:click="disconnect()" alt="Italian Trulli">
        <h1>Account</h1>
        <div class="logins"><p>Username : {{ this.username }}<br>
        E-mail address : {{ this.email }}</p></div>
        
        <button v-on:click="goToMenu()">Upload new file</button>
        <button v-on:click="goToFichiers()">Files</button>
        
        <div v-if="$session.get('username') == 'admin' && $session.get('email') == 'admin@root.com'">
            <h1 style="color: red; font-weight: normal;">ADMIN</h1>
            <h1>All users</h1>
            <div  v-for="user in users" :key="user.id" :class="{selected: user.selected}" @click="select(user)">
                <div style="text-align: center;">
                    <p style="display: inline-block; text-align: left;">
                        Username : {{ user.username }}<br />
                        E-mail address : {{ user.email }}<br />
                        Status : {{ user.status }}
                    </p>
                </div>

                <select v-bind:id="user.id">
                    <option value="activated">Activate account</option>
                    <option value="disabled">Disable account</option>
                </select>
                <button v-on:click="activation(user)">Submit</button>
            </div>
        </div>

      <h1>All files</h1>
        <div v-if="liste[0] == undefined">No result</div>
        <div v-for="item in liste" :key="item.message" class="lineShadow">
            Title : {{ item.titre }} <br> Category : {{ item.categorie }}
            <div class="buttons">
                <button style="margin-bottom: 2px;" v-on:click="supprimer(item)">Delete</button>
                <button v-on:click="modifier(item)">Edit</button>
            </div>
            
            <br>
            <div id="edit" v-if="changeInfos && selected==item">

                <input type="text" id="titre" placeholder="Title">

                <select id="categorie">
                    <option value="vigilant_software_suite">Vigilant Software Suite</option>
                    <option value="vigilant_master_med">Vigilant Master Med</option>
                    <option value="vigilant_sentinel">Vigilant Sentinel</option>
                    <option value="vigilant_insight">Vigilant Insight</option>
                    <option value="vigilant_bridge">Vigilant Bridge</option>
                    <option value="centerium">Vigilant Centerium</option>
                    <option value="partner">Partner</option>
                </select>
                    <button v-on:click="valider()">Submit</button>
            </div>

        </div>
    </div>
</template>


<script>
const axios = require('axios');
import router from '../router'
export default {
  mounted: async function () {
    if (this.$session.get('username') == 'admin' && this.$session.get('email') == 'admin@root.com') {
        var adminData = {
            username: this.$session.get('username')
        }
        var users = await axios.get("https://api.fkv-vss.com/api/getAllUsers", adminData, {
        });

        this.users = users.data.informations
    }

    var data = {
        username: this.$session.get('username')
    }
    var infos = await axios.post("https://api.fkv-vss.com/api/me", data, {

    });
    
    infos = infos.data.informations
    
    this.username = infos.username;
    this.email = infos.email;
    this.id = infos.id;
    this.reload()
  },
    components: {},
    data() {
        return {
        liste: [],
        listeCat: ["vigilant_software_suite","vigilant_master_med","vigilant_sentinel"
                    ,"vigilant_insight","vigilant_bridge","centerium","partner"],
        listeCategories: ["Vigilant Software Suite","Vigilant Master Med","Vigilant Sentinel","Vigilant Insight","Vigilant Bridge"
            ,"Vigilant Centerium","Partner"],
        email: "",
        username: "",
        id: null,
        changeInfos: false,
        selected: undefined,
        users: undefined

        };
    },
    methods: {
        select(project) {
            project.selected = true
        },
        async activation(user) {
            var data = {
                id: user.id,
                status: document.getElementById(user.id).value
            }
            await axios.post("https://api.fkv-vss.com/api/updateUser", data, {
            }).then(async function (response) {
                if (response.data.message == "The user has been updated successfully !") {
                    alert(response.data.message)
                }
                else {
                    alert("Error: The user hasn't been updated")
                }
            })
        },
        async reload() {
            var projects = await axios.get("https://api.fkv-vss.com/api/GetAllFichiers", {});
            for (var i = 0; i < projects.data.informations.length; i++) {
                projects.data.informations[i].categorie = this.listeCategories[
                    this.listeCat.findIndex((element) => element == projects.data.informations[i].categorie)
                    ]
            }
            this.liste = projects.data.informations;
        },
        async valider() {
            var data = {
                id: this.selected.id,
                vignette: this.selected.vignette,
                titre: document.getElementById("titre").value,
                nom: this.selected.nomDoc,
                categorie: document.getElementById("categorie").value,
                oldCategorie: 
                    this.listeCat[
                        this.listeCategories.findIndex((element) => element == this.selected.categorie)
                        ]
                
            }
            if (document.getElementById("titre").value == "") {
                data.titre = this.selected.titre
            }
            var self = this;
            await axios.post("https://api.fkv-vss.com/api/modifier", data, {
            }).then(async function (response) {
                if (response.data.message == "The file has been updated successfully !") {
                    alert(response.data.message)
                    self.changeInfos = false
                    
                    var projects = await axios.get("https://api.fkv-vss.com/api/GetAllFichiers"/*, data*/, {});
                    for (var i = 0; i < projects.data.informations.length; i++) {
                        projects.data.informations[i].categorie = self.listeCategories[
                            self.listeCat.findIndex((element) => element == projects.data.informations[i].categorie)
                            ]
                    }
                    self.liste = projects.data.informations;
                }
            })
        },
        modifier(el) {
            
            this.changeInfos = true
            this.selected = el
            this.$nextTick(() => {
                document.getElementById('categorie').selectedIndex = this.listeCategories.indexOf(this.selected.categorie)
            })
        },
        async supprimer(file) {
            const data = {
                id: file.id,
                nom: file.nomDoc,
                categorie: this.listeCat[
                        this.listeCategories.findIndex((element) => element == file.categorie)
                        ],
                email: this.email,
                vignette: file.vignette
            }
            
            var self = this

            await axios.post("https://api.fkv-vss.com/api/delete", data, {
            }).then(function (response) {
                if (response.data.message == "The file has been deleted successfully !") {
                    self.liste = self.liste.filter(function (el) {
                        return el.id != file.id
                    })
                }
            })
        },
        disconnect() {
            this.$session.destroy()
            router.push('/').catch(() => {})
        },
        goToMenu: function () {
            router.push('/Menu').catch(() => {})
        },
        goToFichiers: function () {
            router.push('/Fichiers').catch(() => {})
        },
    }
}
</script>

<style scoped>
#edit {
    display: inline;
}
#edit button {
    display: inline;
    vertical-align: middle;
}
.buttons {
    display: flex;
    flex-wrap: wrap;
    float: right
}
.buttons button {
    margin-top:auto;
    margin-bottom: auto;
}
.lineShadow {
    padding: 15px;
    text-align: left;
    margin: 14px; 
}
.lineShadow button {
    margin-top:auto;
    margin-bottom: auto;
}
@media only screen and (max-width: 820px) {
    .lineShadow {
        width: 100%;
        display: inline-block;
    }
}
</style>